<template>
  <div>
    <el-container>
      <el-header class="main-header">
        <portal-page-header />
      </el-header>
      <el-main>
        <div class="title">
          选择注册类型
        </div>
        <div class="reason">
          并进行信息完善
        </div>
        <div class="type">
          <div class="type-left" @click="selectUnit = 1">
            <img v-show="selectUnit!=1" src="../../assets/images/register/img-enterprise-user-nor.png" alt="" />
            <img v-show="selectUnit==1" src="../../assets/images/register/img-enterprise-user-sel.png" alt="" />
            <div class="type-name">
              <i v-show="selectUnit!=1"></i>
              <img v-show="selectUnit==1" src="../../assets/images/register/dh.png" alt="" />
              <span>用人单位</span>
            </div>
            <div class="tip">企业危害项目申报，请注册此类账号。</div>
          </div>
          <div class="type-right" @click="selectUnit = 2">
            <img class="icon1" v-show="selectUnit==2" src="../../assets/images/register/icon1.png" alt="" />
            <img class="icon2" v-show="selectUnit==2" src="../../assets/images/register/icon2.png" alt="" />
            <img class="icon3" v-show="selectUnit==2" src="../../assets/images/register/icon3.png" alt="" />
            <img v-show="selectUnit==1 || selectUnit==3" src="../../assets/images/register/img-technical-servicel-nor.png" alt="" />
            <img v-show="selectUnit==2" src="../../assets/images/register/img-technical-servicel-sel.png" alt="" />
            <div class="type-name">
              <i v-show="selectUnit==1 || selectUnit==3"></i>
              <img v-show="selectUnit==2" src="../../assets/images/register/dh.png" alt="" />
              <span>技术服务机构</span>
              <div class="tip">机构开展企业服务，请注册此类账号。</div>
            </div>
          </div>
          <!-- <div class="type-right" @click="selectUnit = 3">
            <img class="icon1" v-show="selectUnit==3" src="../../assets/images/register/icon1.png" alt="" />
            <img class="icon2" v-show="selectUnit==3" src="../../assets/images/register/icon2.png" alt="" />
            <img class="icon3" v-show="selectUnit==3" src="../../assets/images/register/icon3.png" alt="" />
            <img v-show="selectUnit==1 || selectUnit==2" src="../../assets/images/register/img-technical-servicel-nor.png" alt="" />
            <img v-show="selectUnit==3" src="../../assets/images/register/img-technical-servicel-sel.png" alt="" />
            <div class="type-name">
              <i v-show="selectUnit==1"></i>
              <img v-show="selectUnit==3" src="../../assets/images/register/dh.png" alt="" />
              <span>帮扶管家注册</span>
            </div>
          </div> -->
        </div>
        <div class="rbox">
          <div>
            <el-checkbox v-model="checked"></el-checkbox>
            <span style="cursor: pointer;" @click="checked = !checked">我保证提供的资料真实有效，否则将承担因不当注册行为而导致的一切后果。</span>
          </div>
          <!-- <span style="color:#5574DF;cursor: pointer;margin-right:50px;">《服务条款》</span> -->
          <el-button class="zwx-button next-button" type="primary" :class="{ nextButtonDisabled: !checked }" @click="next">
            下一步
          </el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import imageVerification from '@/components/business/imageVerification'
export default {
  name: 'selectRegister',
  components: { imageVerification },
  data() {
    return {
      checked: false,
      selectUnit: 1,
    }
  },
  created() {
    if ([1, 2 ,3].indexOf(this.$route.params.selectUnit) != -1) {
      this.checked = true
      this.selectUnit = this.$route.params.selectUnit 
    }
  },
  methods: {
    next() {
      if (!this.checked) return
      this.$router.push({ name: this.selectUnit==2 ? 'ReigsterUnit' : this.selectUnit==3?'RegisterRunner':'Register', params: {} })
    },
  },
}
</script>
<style lang="less" scoped>
.title {
  margin-top: 50px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Medium;
  font-weight: 500;
  text-align: center;
  color: #3d3f44;
}
.reason {
  height: 100px;
  font-size: 18px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #3d3f44;
  line-height: 100px;
}
.type {
  margin: 0 auto;
  width: 800px;
  display: flex;
  justify-content: space-between;
  &-left {
    width: 50%;
    text-align: center;
    cursor: pointer;
  }
  &-right {
    width: 50%;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  &-name {
    margin-top: 20px;
    height: 30px;
    line-height: 30px;
    font-size: 22px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #3d3f44;
    span {
      padding-left: 5px;
      vertical-align: text-bottom;
    }
    i {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #99adc5;
      border-radius: 50% 50%;
    }
  }
  .tip {
    margin-top: 15px;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #bdbdbd;
  }
}
.rbox {
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 50px;
  width: 800px;
  height: 72px;
  background: #f7f9ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin-left: 10px;
    font-size: 14px;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-weight: 400;
    color: #3a485e;
  }
}
.icon1 {
  position: absolute;
  left: 120px;
  top: 10px;
}
.icon2 {
  position: absolute;
  left: 170px;
  top: 20px;
}
.icon3 {
  position: absolute;
  left: 240px;
  top: 15px;
}
.next-button {
  padding: 10px 30px !important;
  background: #5574df !important;
}
.next-button:hover {
  opacity: 0.8;
}
.nextButtonDisabled {
  padding: 10px 30px !important;
  background: #5574df !important;
  opacity: 0.5 !important;
  cursor: no-drop;
}
</style>
