var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "course-manage-edit" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            rules: _vm.rules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "课程基本信息" }
          }),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "82px",
                        label: "课程名称：",
                        prop: "courseName"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "640px !important" },
                        attrs: {
                          placeholder: "请输入",
                          clearable: "",
                          maxlength: "50"
                        },
                        model: {
                          value: _vm.msForm.courseName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.msForm,
                              "courseName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "msForm.courseName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "82px",
                        label: "课程简介：",
                        prop: "courseDesc"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-textarea",
                        staticStyle: { width: "640px !important" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5, maxRows: 8 },
                          maxlength: "2000",
                          placeholder: "请输入",
                          clearable: ""
                        },
                        model: {
                          value: _vm.msForm.courseDesc,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.msForm,
                              "courseDesc",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "msForm.courseDesc"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showFiled
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "课程分类：",
                            "label-width": "82px",
                            prop: "courseTraineeType"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "zwx-radio-group",
                              staticStyle: { "min-width": "68px!important" },
                              on: { change: _vm.courseTraineeTypeChange },
                              model: {
                                value: _vm.msForm.courseTraineeType,
                                callback: function($$v) {
                                  _vm.$set(_vm.msForm, "courseTraineeType", $$v)
                                },
                                expression: "msForm.courseTraineeType"
                              }
                            },
                            _vm._l(_vm.traineeTypeList, function(item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.codeNo,
                                  staticClass: "zwx-radio",
                                  attrs: { label: item.codeNo }
                                },
                                [_vm._v(_vm._s(item.codeName))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFiled && _vm.msForm.courseTraineeType
                ? _c(
                    "div",
                    { staticClass: "edit-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "课程类型：",
                            "label-width": "82px",
                            prop: "courseTrainingType"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "zwx-radio-group",
                              staticStyle: { "min-width": "68px!important" },
                              model: {
                                value: _vm.msForm.courseTrainingType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.msForm,
                                    "courseTrainingType",
                                    $$v
                                  )
                                },
                                expression: "msForm.courseTrainingType"
                              }
                            },
                            _vm._l(_vm.courseTrainingTypeList, function(item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.codeNo,
                                  staticClass: "zwx-radio",
                                  attrs: { label: item.codeNo }
                                },
                                [_vm._v(_vm._s(item.codeName))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "82px",
                        label: "学时要求：",
                        prop: "requiredClassHour"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        staticStyle: { width: "90px !important" },
                        attrs: {
                          placeholder: "请输入",
                          clearable: "",
                          maxlength: "9"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$validate.nonnegativeDouble(
                              _vm.$data["msForm"],
                              "requiredClassHour",
                              3,
                              1
                            )
                          }
                        },
                        model: {
                          value: _vm.msForm.requiredClassHour,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.msForm,
                              "requiredClassHour",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "msForm.requiredClassHour"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "82px",
                        label: "封面图片：",
                        prop: "annexList"
                      }
                    },
                    [
                      _c(
                        "file-upload",
                        {
                          ref: "coverPictureRef",
                          attrs: {
                            accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,",
                            size: 50 * 1024 * 1024,
                            sizeInfo: "50M"
                          },
                          on: {
                            showFileList: _vm.picFileShowFileList,
                            success: _vm.picFileUploadSuccess
                          }
                        },
                        [
                          _vm.$zwxBase.verifyIsBlank(_vm.msForm.annexList)
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "zwx-button zwx-button-text-26",
                                  attrs: { icon: "el-icon-upload2" }
                                },
                                [_vm._v("上传")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.annexList)
                        ? _c(
                            "div",
                            { staticClass: "show-file" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "zwx-button zwx-button-text-26",
                                  on: {
                                    click: function($event) {
                                      return _vm.openFilePreview1(
                                        _vm.msForm.annexList,
                                        0
                                      )
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "zwx-button zwx-button-text-26",
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deletionPicFile(0)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", {
                staticStyle: { "border-bottom": "1px solid #e1e5f4" }
              }),
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "first" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v("课件")
                      ]),
                      _c(
                        "div",
                        { staticStyle: { padding: "0px 0px 10px 20px" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-icontext-28",
                              attrs: { icon: "el-icon-plus" },
                              on: { click: _vm.addInfo }
                            },
                            [_vm._v("添加")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          key: "msForm.tbCoursewareInfoExpList",
                          staticClass: "zwx-table",
                          attrs: {
                            data: _vm.msForm.tbCoursewareInfoExpList,
                            border: "",
                            stripe: "",
                            "tooltip-effect": "light"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "levelCodeNo",
                              label: "层级编码",
                              width: "200",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          id: "levelCodeNo" + scope.$index,
                                          prop:
                                            "tbCoursewareInfoExpList." +
                                            scope.$index +
                                            ".levelCodeNo",
                                          rules: _vm.rules.levelCodeNo
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            maxlength: "20"
                                          },
                                          model: {
                                            value: scope.row.levelCodeNo,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "levelCodeNo",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.levelCodeNo"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "coursewareType",
                              label: "类别",
                              width: "200",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          id: "coursewareType" + scope.$index,
                                          prop:
                                            "tbCoursewareInfoExpList." +
                                            scope.$index +
                                            ".coursewareType",
                                          rules: _vm.rules.coursewareType
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "zwx-select",
                                            attrs: {
                                              "popper-class":
                                                "zwx-select-popper",
                                              placeholder: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.changeType(
                                                  scope.row.coursewareType,
                                                  scope.$index
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.coursewareType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "coursewareType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.coursewareType"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: { label: "标题", value: 1 }
                                            }),
                                            _c("el-option", {
                                              attrs: { label: "教材", value: 2 }
                                            }),
                                            !_vm.showFiled
                                              ? _c("el-option", {
                                                  attrs: {
                                                    label: "考试",
                                                    value: 3
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "coursewareTitle",
                              label: "内容",
                              width: "600",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          id: "coursewareTitle" + scope.$index,
                                          prop:
                                            "tbCoursewareInfoExpList." +
                                            scope.$index +
                                            ".coursewareTitle",
                                          rules: _vm.rules.coursewareTitle
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          attrs: {
                                            placeholder: "",
                                            maxlength: "100",
                                            disabled: _vm.$zwxBase.verifyIsBlank(
                                              scope.row.coursewareType
                                            ),
                                            "suffix-icon":
                                              scope.row.coursewareType !== 1
                                                ? "el-icon-search"
                                                : ""
                                          },
                                          on: {
                                            focus: function($event) {
                                              return _vm.getMsDialog(
                                                scope.row,
                                                scope.$index
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.coursewareTitle,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "coursewareTitle",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.coursewareTitle"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "requiredCourse",
                              label: "必修",
                              width: "100",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          id: "requiredCourse" + scope.$index,
                                          prop:
                                            "tbCoursewareInfoExpList." +
                                            scope.$index +
                                            ".requiredCourse"
                                        }
                                      },
                                      [
                                        _c("el-checkbox", {
                                          staticClass: "zwx-checkbox",
                                          staticStyle: {
                                            "margin-right": "0px!important"
                                          },
                                          attrs: {
                                            disabled:
                                              scope.row.coursewareType !== 2
                                          },
                                          model: {
                                            value: scope.row.requiredCourse,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "requiredCourse",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.requiredCourse"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "classHour",
                              label: "学时",
                              width: "150",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          id: "classHour" + scope.$index,
                                          prop:
                                            "tbCoursewareInfoExpList." +
                                            scope.$index +
                                            ".classHour"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "zwx-input",
                                          attrs: {
                                            placeholder: "",
                                            clearable: "",
                                            maxlength: "9",
                                            disabled:
                                              scope.row.coursewareType !== 2
                                          },
                                          model: {
                                            value: scope.row.classHour,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "classHour",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "scope.row.classHour"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              "min-width": "160",
                              "header-align": "left",
                              align: "left"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "zwx-button zwx-button-text-26",
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteInfo(scope.$index)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "second" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _vm._v("相关资料")
                      ]),
                      _c(
                        "div",
                        { staticStyle: { padding: "0px 0px 10px 20px" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-icontext-28",
                              attrs: { icon: "el-icon-plus" },
                              on: { click: _vm.addRelatedData }
                            },
                            [_vm._v("添加")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticClass: "zwx-table",
                          attrs: {
                            "row-key": "rid",
                            data: _vm.msForm.tdCourseRelatedDataExpList,
                            border: "",
                            stripe: "",
                            "tooltip-effect": "light"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "fileName",
                              label: "资料名称",
                              width: "500",
                              "header-align": "center",
                              align: "left",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openFilePreview(
                                              scope.row.fileName,
                                              scope.row.filePath
                                            )
                                          }
                                        }
                                      },
                                      [
                                        scope.row.fileType == 1
                                          ? _c("i", {
                                              staticClass:
                                                "colorful chis-icon-colorful-pdf"
                                            })
                                          : _vm._e(),
                                        scope.row.fileType == 2
                                          ? _c("i", {
                                              staticClass:
                                                "colorful chis-icon-colorful-mp4"
                                            })
                                          : _vm._e(),
                                        scope.row.fileType == 3
                                          ? _c("i", {
                                              staticClass:
                                                "colorful chis-icon-colorful-word"
                                            })
                                          : _vm._e(),
                                        scope.row.fileType == 4
                                          ? _c("i", {
                                              staticClass:
                                                "colorful chis-icon-colorful-excel"
                                            })
                                          : _vm._e(),
                                        scope.row.fileType == 5
                                          ? _c("i", {
                                              staticClass:
                                                "colorful chis-icon-colorful-ppt"
                                            })
                                          : _vm._e(),
                                        _c(
                                          "i",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                              color: "blue"
                                            }
                                          },
                                          [_vm._v(_vm._s(scope.row.fileName))]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              fixed: "right",
                              label: "操作",
                              "min-width": 110,
                              "header-align": "left",
                              align: "left"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "zwx-button zwx-button-text-26",
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.materialDeletList(
                                              scope.$index
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "coursewareDialogRef",
          staticClass: "courseware-dialog",
          attrs: {
            title: "添加",
            pagination: true,
            total:
              _vm.coursewareDialogForm.coursewareType === 2
                ? _vm.coursewareDialogForm.materialTotal
                : _vm.coursewareDialogForm.examTotal
          },
          on: {
            determine: _vm.coursewareDialogSubmit,
            cancel: _vm.coursewareDialogCancel,
            close: _vm.coursewareDialogClose,
            currentPage: _vm.coursewareDialogCurrent
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "coursewareDialogForm",
              staticClass: "zwx-form",
              staticStyle: { height: "50px!important" },
              attrs: {
                model: _vm.coursewareDialogForm,
                rules: _vm.coursewareDialogFormRules
              }
            },
            [
              _c(
                "div",
                { staticClass: "edit-form" },
                [
                  _vm.coursewareDialogForm.coursewareType === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "文件名称：", "label-width": "70px" }
                        },
                        [
                          _c("el-input", {
                            staticClass: "zwx-input cc-input",
                            staticStyle: {
                              cursor: "pointer",
                              "min-width": "30px!important",
                              width: "295px!important"
                            },
                            attrs: {
                              placeholder: "输入查询",
                              maxlength: "200",
                              clearable: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.getTeachingMaterialList(
                                  1,
                                  _vm.coursewareDialogForm.coursewareIndex
                                )
                              }
                            },
                            model: {
                              value: _vm.coursewareDialogForm.materialNameLike,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.coursewareDialogForm,
                                  "materialNameLike",
                                  $$v
                                )
                              },
                              expression:
                                "coursewareDialogForm.materialNameLike"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.coursewareDialogForm.coursewareType === 3
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "考试名称：", "label-width": "90px" }
                        },
                        [
                          _c("el-input", {
                            staticClass: "zwx-input cc-input",
                            staticStyle: {
                              cursor: "pointer",
                              "min-width": "30px!important",
                              width: "300px!important"
                            },
                            attrs: {
                              placeholder: "",
                              maxlength: "50",
                              clearable: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.getExamConfigList(
                                  1,
                                  _vm.coursewareDialogForm.coursewareIndex
                                )
                              }
                            },
                            model: {
                              value: _vm.coursewareDialogForm.examNameLike,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.coursewareDialogForm,
                                  "examNameLike",
                                  $$v
                                )
                              },
                              expression: "coursewareDialogForm.examNameLike"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm.coursewareDialogForm.coursewareType === 2
            ? _c(
                "el-table",
                {
                  ref: "teachingMaterialTable",
                  staticClass: "zwx-table",
                  attrs: {
                    "row-key": "rid",
                    data: _vm.coursewareDialogForm.teachingMaterialList,
                    border: "",
                    stripe: "",
                    "tooltip-effect": "light",
                    "max-height": "310px"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "选择",
                      width: "80",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-radio", {
                                staticClass: "zwx-radio-course",
                                staticStyle: {
                                  "margin-right": "unset !important",
                                  width: "15px"
                                },
                                attrs: { label: scope.row.uuid },
                                on: {
                                  change: function($event) {
                                    return _vm.handleCoursewareSelectionChange(
                                      scope.row
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.coursewareDialogForm.selectRadio,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coursewareDialogForm,
                                      "selectRadio",
                                      $$v
                                    )
                                  },
                                  expression: "coursewareDialogForm.selectRadio"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3459871490
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileName",
                      label: "文件名称",
                      width: "600",
                      "header-align": "center",
                      align: "left",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fileType",
                      label: "文件类别",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.fileType == 1
                                ? _c("span", [_vm._v("pdf")])
                                : _vm._e(),
                              scope.row.fileType == 2
                                ? _c("span", [_vm._v("视频")])
                                : _vm._e(),
                              scope.row.fileType == 3
                                ? _c("span", [_vm._v("word")])
                                : _vm._e(),
                              scope.row.fileType == 4
                                ? _c("span", [_vm._v("excel")])
                                : _vm._e(),
                              scope.row.fileType == 5
                                ? _c("span", [_vm._v("ppt")])
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3439735603
                    )
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.coursewareDialogForm.coursewareType === 3
            ? _c(
                "el-table",
                {
                  ref: "examConfigTable",
                  staticClass: "zwx-table",
                  attrs: {
                    "row-key": "rid",
                    data: _vm.coursewareDialogForm.examConfigList,
                    border: "",
                    stripe: "",
                    "tooltip-effect": "light",
                    "max-height": "310px"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "选择",
                      width: "80",
                      "header-align": "center",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-radio", {
                                staticClass: "zwx-radio-course",
                                staticStyle: {
                                  "margin-right": "unset !important",
                                  width: "15px"
                                },
                                attrs: { label: scope.row.rid },
                                on: {
                                  change: function($event) {
                                    return _vm.handleCoursewareSelectionChange(
                                      scope.row
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.coursewareDialogForm.selectRadio,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.coursewareDialogForm,
                                      "selectRadio",
                                      $$v
                                    )
                                  },
                                  expression: "coursewareDialogForm.selectRadio"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3270996112
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "examName",
                      label: "考试名称",
                      width: "300",
                      "header-align": "center",
                      align: "left"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "examTime",
                      label: "考试时长",
                      width: "150",
                      "header-align": "center",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "totalScore",
                      label: "总分数",
                      width: "150",
                      "header-align": "center",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "qualifiedScore",
                      label: "合格分数",
                      fixed: "right",
                      "min-width": 110,
                      "header-align": "center",
                      align: "center"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "relatedDataDialogRef",
          staticClass: "relatedData-dialog",
          attrs: {
            title: "添加",
            pagination: true,
            total: _vm.relatedDataDialogForm.relatedDataTotal
          },
          on: {
            determine: _vm.relatedDataSubmit,
            cancel: function($event) {
              return _vm.relatedDataDialogCancel()
            },
            close: _vm.relatedDataDialogClose,
            currentPage: _vm.relatedDataCurrent
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "relatedDataDialogForm",
              staticClass: "zwx-form",
              staticStyle: { height: "50px!important" },
              attrs: { model: _vm.relatedDataDialogForm }
            },
            [
              _c(
                "div",
                { staticClass: "edit-form" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "文件名称：", "label-width": "70px" } },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input cc-input",
                        staticStyle: {
                          cursor: "pointer",
                          "min-width": "30px!important",
                          width: "295px!important"
                        },
                        attrs: {
                          placeholder: "输入查询",
                          maxlength: "200",
                          clearable: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.getRelatedDataList(1)
                          }
                        },
                        model: {
                          value: _vm.relatedDataDialogForm.relatedDataNameLike,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.relatedDataDialogForm,
                              "relatedDataNameLike",
                              $$v
                            )
                          },
                          expression:
                            "relatedDataDialogForm.relatedDataNameLike"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-table",
            {
              staticClass: "zwx-table",
              attrs: {
                "row-key": "rid",
                data: _vm.relatedDataDialogForm.relatedDataList,
                border: "",
                stripe: "",
                "tooltip-effect": "light",
                height: "430px"
              },
              on: { "selection-change": _vm.relatedDataSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fileName",
                  label: "文件名称",
                  "header-align": "center",
                  align: "left",
                  width: "420"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fileType",
                  label: "文件类别",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.fileType == 1
                          ? _c("span", [_vm._v("pdf")])
                          : _vm._e(),
                        scope.row.fileType == 2
                          ? _c("span", [_vm._v("视频")])
                          : _vm._e(),
                        scope.row.fileType == 3
                          ? _c("span", [_vm._v("word")])
                          : _vm._e(),
                        scope.row.fileType == 4
                          ? _c("span", [_vm._v("excel")])
                          : _vm._e(),
                        scope.row.fileType == 5
                          ? _c("span", [_vm._v("ppt")])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary", disabled: _vm.saveButtonDisabled },
              on: { click: _vm.addOrModifyTrainingCourse }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }