var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { staticClass: "main-header" },
            [_c("portal-page-header")],
            1
          ),
          _c("el-main", [
            _c("div", { staticClass: "title" }, [_vm._v(" 选择注册类型 ")]),
            _c("div", { staticClass: "reason" }, [_vm._v(" 并进行信息完善 ")]),
            _c("div", { staticClass: "type" }, [
              _c(
                "div",
                {
                  staticClass: "type-left",
                  on: {
                    click: function($event) {
                      _vm.selectUnit = 1
                    }
                  }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectUnit != 1,
                        expression: "selectUnit!=1"
                      }
                    ],
                    attrs: {
                      src: require("../../assets/images/register/img-enterprise-user-nor.png"),
                      alt: ""
                    }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectUnit == 1,
                        expression: "selectUnit==1"
                      }
                    ],
                    attrs: {
                      src: require("../../assets/images/register/img-enterprise-user-sel.png"),
                      alt: ""
                    }
                  }),
                  _c("div", { staticClass: "type-name" }, [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectUnit != 1,
                          expression: "selectUnit!=1"
                        }
                      ]
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectUnit == 1,
                          expression: "selectUnit==1"
                        }
                      ],
                      attrs: {
                        src: require("../../assets/images/register/dh.png"),
                        alt: ""
                      }
                    }),
                    _c("span", [_vm._v("用人单位")])
                  ]),
                  _c("div", { staticClass: "tip" }, [
                    _vm._v("企业危害项目申报，请注册此类账号。")
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "type-right",
                  on: {
                    click: function($event) {
                      _vm.selectUnit = 2
                    }
                  }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectUnit == 2,
                        expression: "selectUnit==2"
                      }
                    ],
                    staticClass: "icon1",
                    attrs: {
                      src: require("../../assets/images/register/icon1.png"),
                      alt: ""
                    }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectUnit == 2,
                        expression: "selectUnit==2"
                      }
                    ],
                    staticClass: "icon2",
                    attrs: {
                      src: require("../../assets/images/register/icon2.png"),
                      alt: ""
                    }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectUnit == 2,
                        expression: "selectUnit==2"
                      }
                    ],
                    staticClass: "icon3",
                    attrs: {
                      src: require("../../assets/images/register/icon3.png"),
                      alt: ""
                    }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectUnit == 1 || _vm.selectUnit == 3,
                        expression: "selectUnit==1 || selectUnit==3"
                      }
                    ],
                    attrs: {
                      src: require("../../assets/images/register/img-technical-servicel-nor.png"),
                      alt: ""
                    }
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.selectUnit == 2,
                        expression: "selectUnit==2"
                      }
                    ],
                    attrs: {
                      src: require("../../assets/images/register/img-technical-servicel-sel.png"),
                      alt: ""
                    }
                  }),
                  _c("div", { staticClass: "type-name" }, [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectUnit == 1 || _vm.selectUnit == 3,
                          expression: "selectUnit==1 || selectUnit==3"
                        }
                      ]
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectUnit == 2,
                          expression: "selectUnit==2"
                        }
                      ],
                      attrs: {
                        src: require("../../assets/images/register/dh.png"),
                        alt: ""
                      }
                    }),
                    _c("span", [_vm._v("技术服务机构")]),
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("机构开展企业服务，请注册此类账号。")
                    ])
                  ])
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "rbox" },
              [
                _c(
                  "div",
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.checked,
                        callback: function($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked"
                      }
                    }),
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            _vm.checked = !_vm.checked
                          }
                        }
                      },
                      [
                        _vm._v(
                          "我保证提供的资料真实有效，否则将承担因不当注册行为而导致的一切后果。"
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "zwx-button next-button",
                    class: { nextButtonDisabled: !_vm.checked },
                    attrs: { type: "primary" },
                    on: { click: _vm.next }
                  },
                  [_vm._v(" 下一步 ")]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }