<template>
  <div class="course-manage-edit">
    <el-form class="zwx-form edit-form" key="msForm" ref="msForm" :model="msForm" :rules="rules" label-position="right" @submit.native.prevent>
      <base-headline title="课程基本信息" class="no-top" />
      <div>
        <div class="edit-row">
          <el-form-item label-width="82px" label="课程名称：" prop="courseName">
            <el-input style="width:640px !important" class="zwx-input" v-model.trim="msForm.courseName" placeholder="请输入" clearable maxlength="50" />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="82px" label="课程简介：" prop="courseDesc">
            <el-input class="zwx-textarea" type="textarea" v-model.trim="msForm.courseDesc" style="width: 640px !important;" :autosize="{ minRows: 5, maxRows: 8 }" maxlength="2000" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div v-if="showFiled" class="edit-row">
          <el-form-item label="课程分类：" label-width="82px" prop="courseTraineeType">
            <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.courseTraineeType" @change="courseTraineeTypeChange">
              <el-radio v-for="item in traineeTypeList" :key="item.codeNo" class="zwx-radio" :label="item.codeNo">{{ item.codeName }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div v-if="showFiled && msForm.courseTraineeType" class="edit-row">
          <el-form-item label="课程类型：" label-width="82px" prop="courseTrainingType">
            <el-radio-group class="zwx-radio-group" style="min-width:68px!important" v-model="msForm.courseTrainingType">
              <el-radio v-for="item in courseTrainingTypeList" :key="item.codeNo" class="zwx-radio" :label="item.codeNo">{{ item.codeName }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="edit-row">
          <!-- <el-form-item label-width="110px" label="包含考试：" prop="existExam">
            <el-radio-group class="zwx-radio-group" v-model="msForm.existExam" style="width: 20px !important;min-width: 77px!important;">
              <el-radio class="zwx-radio" :label="true">是</el-radio>
              <el-radio class="zwx-radio" :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label-width="82px" label="学时要求：" prop="requiredClassHour">
            <el-input style="width:90px !important" class="zwx-input" v-model.trim="msForm.requiredClassHour" placeholder="请输入" clearable maxlength="9" @input="$validate.nonnegativeDouble($data['msForm'], 'requiredClassHour', 3, 1)" />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="82px" label="封面图片：" prop="annexList">
            <file-upload ref="coverPictureRef" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG," @showFileList="picFileShowFileList" @success="picFileUploadSuccess" :size="50 * 1024 * 1024" sizeInfo="50M">
              <el-button class="zwx-button zwx-button-text-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(msForm.annexList)">上传</el-button>
            </file-upload>
            <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(msForm.annexList)">
              <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(msForm.annexList, 0)">查看</el-button>
              <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile(0)">删除</el-button>
            </div>
          </el-form-item>
        </div>
        <div style="border-bottom:1px solid #e1e5f4" />
        <el-tabs v-model="activeName">
          <el-tab-pane name="first">
            <span slot="label">课件</span>
            <div style="padding:0px 0px 10px 20px"><el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addInfo">添加</el-button></div>
            <el-table class="zwx-table" key="msForm.tbCoursewareInfoExpList" :data="msForm.tbCoursewareInfoExpList" border stripe tooltip-effect="light">
              <el-table-column prop="levelCodeNo" label="层级编码" width="200" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-form-item :id="'levelCodeNo' + scope.$index" :prop="'tbCoursewareInfoExpList.' + scope.$index + '.levelCodeNo'" :rules="rules.levelCodeNo">
                    <el-input class="zwx-input" v-model="scope.row.levelCodeNo" placeholder="" clearable maxlength="20" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="coursewareType" label="类别" width="200" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-form-item :id="'coursewareType' + scope.$index" :prop="'tbCoursewareInfoExpList.' + scope.$index + '.coursewareType'" :rules="rules.coursewareType">
                    <el-select class="zwx-select" popper-class="zwx-select-popper" v-model="scope.row.coursewareType" placeholder="" @change="changeType(scope.row.coursewareType, scope.$index)">
                      <el-option label="标题" :value="1"></el-option>
                      <el-option label="教材" :value="2"></el-option>
                      <el-option v-if="!showFiled" label="考试" :value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="coursewareTitle" label="内容" width="600" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-form-item :id="'coursewareTitle' + scope.$index" :prop="'tbCoursewareInfoExpList.' + scope.$index + '.coursewareTitle'" :rules="rules.coursewareTitle">
                    <el-input class="zwx-input" v-model="scope.row.coursewareTitle" placeholder="" maxlength="100" @focus="getMsDialog(scope.row, scope.$index)" :disabled="$zwxBase.verifyIsBlank(scope.row.coursewareType)" :suffix-icon="scope.row.coursewareType !== 1 ? 'el-icon-search' : ''" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="requiredCourse" label="必修" width="100" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-form-item :id="'requiredCourse' + scope.$index" :prop="'tbCoursewareInfoExpList.' + scope.$index + '.requiredCourse'">
                    <el-checkbox class="zwx-checkbox" v-model.trim="scope.row.requiredCourse" :disabled="scope.row.coursewareType !== 2" style="margin-right: 0px!important;" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="classHour" label="学时" width="150" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-form-item :id="'classHour' + scope.$index" :prop="'tbCoursewareInfoExpList.' + scope.$index + '.classHour'">
                    <el-input class="zwx-input" v-model.trim="scope.row.classHour" placeholder="" clearable maxlength="9" :disabled="scope.row.coursewareType !== 2" />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="160" header-align="left" align="left">
                <template slot-scope="scope">
                  <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deleteInfo(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane name="second">
            <span slot="label">相关资料</span>
            <div style="padding:0px 0px 10px 20px"><el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addRelatedData">添加</el-button></div>
            <el-table class="zwx-table" row-key="rid" :data="msForm.tdCourseRelatedDataExpList" border stripe tooltip-effect="light">
              <el-table-column prop="fileName" label="资料名称" width="500" header-align="center" align="left" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span @click="openFilePreview(scope.row.fileName, scope.row.filePath)" style="cursor: pointer;">
                    <i v-if="scope.row.fileType == 1" class="colorful chis-icon-colorful-pdf"></i>
                    <i v-if="scope.row.fileType == 2" class="colorful chis-icon-colorful-mp4"></i>
                    <i v-if="scope.row.fileType == 3" class="colorful chis-icon-colorful-word"></i>
                    <i v-if="scope.row.fileType == 4" class="colorful chis-icon-colorful-excel"></i>
                    <i v-if="scope.row.fileType == 5" class="colorful chis-icon-colorful-ppt"></i>
                    <i style="margin-left:5px;color:blue">{{ scope.row.fileName }}</i>
                  </span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" :min-width="110" header-align="left" align="left">
                <template slot-scope="scope">
                  <el-button class="zwx-button zwx-button-text-26 " type="danger" @click="materialDeletList(scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-form>

    <!-- 教材/考试 弹框 -->
    <base-dialog ref="coursewareDialogRef" class="courseware-dialog" @determine="coursewareDialogSubmit" title="添加" @cancel="coursewareDialogCancel" @close="coursewareDialogClose" :pagination="true" @currentPage="coursewareDialogCurrent" :total="coursewareDialogForm.coursewareType === 2 ? coursewareDialogForm.materialTotal : coursewareDialogForm.examTotal">
      <el-form ref="coursewareDialogForm" class="zwx-form" :model="coursewareDialogForm" style="height: 50px!important;" :rules="coursewareDialogFormRules">
        <div class="edit-form">
          <el-form-item label="文件名称：" label-width="70px" v-if="coursewareDialogForm.coursewareType === 2">
            <el-input class="zwx-input cc-input " style="cursor: pointer;min-width:30px!important;width: 295px!important;" placeholder="输入查询" v-model="coursewareDialogForm.materialNameLike" maxlength="200" @input="getTeachingMaterialList(1, coursewareDialogForm.coursewareIndex)" clearable></el-input>
          </el-form-item>
          <el-form-item label="考试名称：" label-width="90px" v-if="coursewareDialogForm.coursewareType === 3">
            <el-input class="zwx-input cc-input " style="cursor: pointer;min-width:30px!important;width: 300px!important;" placeholder="" v-model="coursewareDialogForm.examNameLike" maxlength="50" @input="getExamConfigList(1, coursewareDialogForm.coursewareIndex)" clearable></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 教材列表展示部分 -->
      <el-table ref="teachingMaterialTable" class="zwx-table" row-key="rid" :data="coursewareDialogForm.teachingMaterialList" border stripe tooltip-effect="light" max-height="310px" v-if="coursewareDialogForm.coursewareType === 2">
        <el-table-column label="选择" width="80" header-align="center" align="center">
          <template slot-scope="scope">
            <el-radio class="zwx-radio-course" v-model="coursewareDialogForm.selectRadio" :label="scope.row.uuid" style="margin-right: unset !important;width: 15px;" @change="handleCoursewareSelectionChange(scope.row)"></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称" width="600" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fileType" label="文件类别" header-align="center" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.fileType == 1">pdf</span>
            <span v-if="scope.row.fileType == 2">视频</span>
            <span v-if="scope.row.fileType == 3">word</span>
            <span v-if="scope.row.fileType == 4">excel</span>
            <span v-if="scope.row.fileType == 5">ppt</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 考试列表展示部分 -->
      <el-table ref="examConfigTable" class="zwx-table" row-key="rid" :data="coursewareDialogForm.examConfigList" border stripe tooltip-effect="light" max-height="310px" v-if="coursewareDialogForm.coursewareType === 3">
        <el-table-column label="选择" width="80" header-align="center" align="center">
          <template slot-scope="scope">
            <el-radio class="zwx-radio-course" v-model="coursewareDialogForm.selectRadio" :label="scope.row.rid" style="margin-right: unset !important;width: 15px;" @change="handleCoursewareSelectionChange(scope.row)"></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="examName" label="考试名称" width="300" header-align="center" align="left"></el-table-column>
        <el-table-column prop="examTime" label="考试时长" width="150" header-align="center" align="center"></el-table-column>
        <el-table-column prop="totalScore" label="总分数" width="150" header-align="center" align="center"></el-table-column>
        <el-table-column prop="qualifiedScore" label="合格分数" fixed="right" :min-width="110" header-align="center" align="center"></el-table-column>
      </el-table>
    </base-dialog>

    <!---相关资料弹框-->
    <base-dialog ref="relatedDataDialogRef" class="relatedData-dialog" @determine="relatedDataSubmit" title="添加" @cancel="relatedDataDialogCancel()" @close="relatedDataDialogClose" :pagination="true" @currentPage="relatedDataCurrent" :total="relatedDataDialogForm.relatedDataTotal">
      <el-form ref="relatedDataDialogForm" class="zwx-form" :model="relatedDataDialogForm" style="height: 50px!important;">
        <div class="edit-form">
          <el-form-item label="文件名称：" label-width="70px">
            <el-input class="zwx-input cc-input " style="cursor: pointer;min-width:30px!important;width: 295px!important;" placeholder="输入查询" v-model="relatedDataDialogForm.relatedDataNameLike" maxlength="200" @input="getRelatedDataList(1)" clearable></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 列表展示部分 -->
      <el-table class="zwx-table" row-key="rid" :data="relatedDataDialogForm.relatedDataList" @selection-change="relatedDataSelectionChange" border stripe tooltip-effect="light" height="430px">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="fileName" label="文件名称" header-align="center" align="left" width="420"></el-table-column>
        <el-table-column prop="fileType" label="文件类别" header-align="center" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.fileType == 1">pdf</span>
            <span v-if="scope.row.fileType == 2">视频</span>
            <span v-if="scope.row.fileType == 3">word</span>
            <span v-if="scope.row.fileType == 4">excel</span>
            <span v-if="scope.row.fileType == 5">ppt</span>
          </template>
        </el-table-column>
      </el-table>
    </base-dialog>

    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="addOrModifyTrainingCourse" :disabled="saveButtonDisabled">保存</el-button>
    </fixed-footer>
  </div>
</template>

<script>
export default {
  name: 'CourseManageEdit',
  data() {
    return {
      api: this.$store.state.api,
      view: true,
      type: this.$route.params.type,
      activeName: 'first',
      //是否显示课程种类、课程分类、课程类型
      showFiled: this.$route.meta.showFiled,
      //获取的课程分类、课程类型层级数组
      dataList: [],
      // 课程分类数组
      traineeTypeList: [],
      //课程类型数组
      courseTrainingTypeList: [],
      simpleCodeList: [], // 题目类别层级
      rid: '',
      msForm: {
        uuid: '',
        courseName: '',
        courseDesc: '',
        requiredClassHour: '',
        coverPicture: '',
        existExam: !this.$route.meta.showFiled,
        ifEnable: '',
        courseTraineeType: '', //课程分类    默认数据源第一项
        courseTrainingType: '', //课程类型
        annexList: [], //封面图片集合
        tbCoursewareInfoExpList: [], //课件信息集合
        tdCourseRelatedDataExpList: [], //课程相关资料集合
        courseType: 1,
      },
      coursewareDialogForm: {
        // 教材 or 考试 弹框
        coursewareType: '', //课件选择框 1:标题 2:教材 3:考试
        coursewareIndex: '', //课件列表标记
        coursewareTitle: '', //弹框选择的 教材名称/考试名称
        coursewareCurrentPage: '', // 弹框页码
        pageSize: 18,
        teachingMaterialUid: '', // 教材id
        examId: '', // 考试id
        materialNameLike: '', //教材名称查询
        materialTotal: 0, // 教材分页总数
        classHour: '', //教材 学时
        teachingMaterialList: [], //教材集合
        examNameLike: '', //考试名称查询
        examTotal: 0, //考试分页总数
        examConfigList: [], //考试集合
        selectRadio: '', //弹框列表 选中
      },
      relatedDataDialogForm: {
        // 相关资料弹框
        relatedDataNameLike: '', //资料名称查询
        dataId: '', //资料id
        relatedDataTotal: 0,
        pageSize: 18,
        relatedDataCurrentPage: '',
        relatedDataList: [], // 弹框 列表集合
      },
      multipleSelection: [], //资料多选集合
      loading: null,
      // 校验规则
      rules: {
        courseName: [{ required: true, message: '请输入课程名称', trigger: 'blur' }],
        existExam: [{ required: true, message: '请选择是否包含考试', trigger: 'change' }],
        requiredClassHour: [{ required: true, message: '请输入必须学时数', trigger: 'blur' }],
        levelCodeNo: [{ required: true, validator: this.levelCodeNoValidate, trigger: ['change', 'blur'] }],
        coursewareType: [{ required: true, message: '请选择课件类别', trigger: ['change'] }],
        coursewareTitle: [{ required: true, message: '不能为空', trigger: ['change', 'blur'] }],
        courseTraineeType: [{ required: false, message: '课程分类不能为空', trigger: ['change', 'blur'] }],
        courseTrainingType: [{ required: false, message: '课程类型不能为空', trigger: ['change', 'blur'] }],
        annexList: [{ required: true, message: '请上传封面图片', trigger: ['change', 'blur'] }],
      },
      coursewareDialogFormRules: {},
      msFormOld: null,
      folder: '/onlineTraining', //文件存储路径
      config: {},
    }
  },
  created() { },
  mounted() {
    this.$SimpleCodeTools.getConfig(data => {
      this.config = data
    })
    // 判断添加 还是编辑
    this.pageRouter()
    //课程分类、课程类型
    if (this.showFiled) {
      this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('5002', data => {
        this.dataList = this.manyListFul(data['5002'], '')
        this.traineeTypeList = this.dataList.map(item => {
          return Object.assign(
            {},
            {
              codeName: item.codeName,
              codeNo: item.codeNo,
            }
          )
        })
        // 课程分类默认数据第一项
        this.msForm.courseTraineeType = this.traineeTypeList.length > 0 && this.traineeTypeList[0].codeNo
        // 课程类型默认是数据第一项的下级数据
        this.dataList.forEach(item => {
          if (this.msForm.courseTraineeType == item.codeNo) {
            this.courseTrainingTypeList = item.children
          }
        })
        this.msForm.courseTrainingType = this.courseTrainingTypeList[0].codeNo
      })
      this.rules.courseTraineeType[0].required = true
      this.rules.courseTrainingType[0].required = true
    }
  },
  activated() {
    if (this.$route.params.fresh) this.query()
  },
  computed: {
    saveButtonDisabled() {
      if (JSON.stringify(this.msForm) !== this.msFormOld) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    // 课程分类change事件
    courseTraineeTypeChange(val) {
      this.dataList.forEach(item => {
        if (val == item.codeNo) {
          this.courseTrainingTypeList = item.children
        }
      })
      this.msForm.courseTrainingType = this.courseTrainingTypeList[0].codeNo
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    /**
     * 页面路由回调
     */
    pageRouter() {
      if (this.type === 'add') {
        this.$emit('breadcrumb', '添加', true)
        this.msFormOld = JSON.stringify(this.msForm)
        this.rid = ''
      }
      if (this.type === 'edit') {
        this.$emit('breadcrumb', '编辑', true)
        this.rid = this.$route.params.rid
        this.getDetail(this.rid)
        this.getTeachingMaterialList(1, null)
        this.getExamConfigList(1, null)
      }
    },
    /**
     * 获取详情
     */
    getDetail(rid) {
      let data = {
        uuid: rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getTrainingCourse-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msForm.uuid = data.tbTrainingCourseExp.uuid
            this.msForm.courseName = data.tbTrainingCourseExp.courseName
            this.msForm.requiredClassHour = data.tbTrainingCourseExp.requiredClassHour
            this.msForm.courseDesc = data.tbTrainingCourseExp.courseDesc
            this.msForm.existExam = data.tbTrainingCourseExp.existExam
            this.msForm.ifEnable = data.tbTrainingCourseExp.ifEnable
            this.msForm.coverPicture = data.tbTrainingCourseExp.coverPicture
            if (this.$zwxBase.verifyIsNotBlank(data.tbTrainingCourseExp.coverPicture)) {
              this.msForm.annexList.push({
                fileIconShow: 'ms-file-jpg-icon',
                check: false,
                filePath: data.tbTrainingCourseExp.coverPicture,
              })
            }
            this.msForm.tbCoursewareInfoExpList = data.tbTrainingCourseExp.tbCoursewareInfoExpList || []
            this.msForm.tdCourseRelatedDataExpList = data.tbTrainingCourseExp.tdCourseRelatedDataExpList || []

            // 无锡参数赋值------开始
            if (this.showFiled) {
              this.msForm.courseType = 1
              this.msForm.courseTraineeType = data.tbTrainingCourseExp.courseTraineeType
              this.dataList.forEach(item => {
                if (this.msForm.courseTraineeType == item.codeNo) {
                  this.courseTrainingTypeList = item.children
                }
              })
              this.msForm.courseTrainingType = data.tbTrainingCourseExp.courseTrainingType
            }
            // 无锡参数赋值------结束
            this.msFormOld = JSON.stringify(this.msForm)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 课程图片文件上传显示回调
     */
    picFileShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.msForm.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msForm.annexList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid()
      }
    },
    /**
     * 课程图片文件上传成功回调
     */
    picFileUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        this.$refs.msForm.validateField('annexList')
        for (let i in this.msForm.annexList) {
          for (let j in fileList) {
            if (this.msForm.annexList[i].fileName === fileList[j].fileName) {
              this.msForm.annexList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      // 保存
      //this.saveData()
    },
    /**
     * 删除课程图片文件
     */
    deletionPicFile(index) {
      let fileName = this.msForm.annexList[index].fileName
      let filePath = this.msForm.annexList[index].filePath
      this.msForm.annexList.splice(index, 1)
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.coverPictureRef.deleteIndex(index)
      }
      let fileList = this.$refs.coverPictureRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.coverPictureRef.deleteIndex(j)
            fileList = this.$refs.coverPictureRef.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 课件添加
     */
    addInfo() {
      let data = {
        rid: '',
        levelCodeNo: '',
        coursewareType: '',
        coursewareTitle: '',
        classHour: '',
        requiredCourse: false,
        teachingMaterialUid: '',
        examId: '',
      }
      this.msForm.tbCoursewareInfoExpList.push(data)
    },
    /**
     * 课件删除
     */
    deleteInfo(index) {
      this.msForm.tbCoursewareInfoExpList.splice(index, 1)
    },
    /**
     * 下面自定义
     * 课件部分
     * */
    //自定义表格课件类别改变方法
    changeType(coursewareType, index) {
      if (this.$zwxBase.verifyIsNotBlank(coursewareType)) {
        if (coursewareType == 1) {
          //标题
          if (this.$zwxBase.verifyIsNotBlank(this.msForm.tbCoursewareInfoExpList[index].classHour)) {
            this.$set(this.msForm.tbCoursewareInfoExpList[index], 'classHour', null)
          }
        }
        if (coursewareType == 2) {
          // 教材
          this.getTeachingMaterialList(1, null)
        }
        if (coursewareType == 3) {
          // 考试
          this.getExamConfigList(1, null)
          if (this.$zwxBase.verifyIsNotBlank(this.msForm.tbCoursewareInfoExpList[index].classHour)) {
            this.$set(this.msForm.tbCoursewareInfoExpList[index], 'classHour', null)
          }
        }
        this.$set(this.msForm.tbCoursewareInfoExpList[index], 'coursewareTitle', null)
        this.$set(this.msForm.tbCoursewareInfoExpList[index], 'requiredCourse', null)
        this.$set(this.msForm.tbCoursewareInfoExpList[index], 'examId', null)
        this.$set(this.msForm.tbCoursewareInfoExpList[index], 'teachingMaterialUid', null)
      }
    },
    /**
     * 课件内容 点击,根据类别 打开对应弹框
     */
    getMsDialog(row, index) {
      this.coursewareDialogForm.coursewareIndex = index
      if (this.$zwxBase.verifyIsNotBlank(row.coursewareType)) {
        if (row.coursewareType === '2' || row.coursewareType === 2) {
          this.coursewareDialogForm.coursewareType = 2
          this.coursewareDialogForm.selectRadio = row.teachingMaterialUid + ''
          this.coursewareDialogForm.coursewareTitle = row.coursewareTitle
          this.getTeachingMaterialList(1, this.coursewareDialogForm.coursewareIndex)
          this.$refs.coursewareDialogRef.show(true)
        } else if (row.coursewareType === '3' || row.coursewareType === 3) {
          this.coursewareDialogForm.coursewareType = 3
          this.coursewareDialogForm.selectRadio = row.examId + ''
          this.coursewareDialogForm.coursewareTitle = row.coursewareTitle
          this.getExamConfigList(1, this.coursewareDialogForm.coursewareIndex)
          this.$refs.coursewareDialogRef.show(true)
        }
      }
    },
    /**
     * 教材/考试 弹框 取消
     */
    coursewareDialogCancel() {
      this.$refs.coursewareDialogRef.show(false)
    },
    /**
     * 教材/考试 弹框 关闭
     */
    coursewareDialogClose() {
      this.coursewareDialogForm.coursewareType = ''
      this.coursewareDialogForm.coursewareIndex = ''
      this.coursewareDialogForm.coursewareTitle = ''
      this.coursewareDialogForm.coursewareCurrentPage = ''
      this.coursewareDialogForm.selectRadio = ''
      // 教材
      if (this.coursewareDialogForm.coursewareType == 2) {
        this.coursewareDialogForm.teachingMaterialUid = ''
        this.coursewareDialogForm.materialNameLike = ''
        this.coursewareDialogForm.classHour = ''
        this.coursewareDialogForm.examNameLike = ''
      } else {
        // 考试
        this.coursewareDialogForm.examId = ''
        this.coursewareDialogForm.examNameLike = ''
        this.coursewareDialogForm.materialNameLike = ''
      }
    },
    /**
     * 教材/考试 弹框确定
     */
    coursewareDialogSubmit() {
      this.$set(this.msForm.tbCoursewareInfoExpList[this.coursewareDialogForm.coursewareIndex], 'coursewareTitle', this.coursewareDialogForm.coursewareTitle)
      if (this.coursewareDialogForm.coursewareType === 2) {
        if (this.$zwxBase.verifyIsNotBlank(this.coursewareDialogForm.teachingMaterialUid)) {
          this.$set(this.msForm.tbCoursewareInfoExpList[this.coursewareDialogForm.coursewareIndex], 'teachingMaterialUid', this.coursewareDialogForm.teachingMaterialUid)
        }
        if (this.$zwxBase.verifyIsNotBlank(this.coursewareDialogForm.classHour)) {
          this.$set(this.msForm.tbCoursewareInfoExpList[this.coursewareDialogForm.coursewareIndex], 'classHour', this.coursewareDialogForm.classHour)
        } else {
          this.$set(this.msForm.tbCoursewareInfoExpList[this.coursewareDialogForm.coursewareIndex], 'classHour', null)
        }
      }
      if (this.coursewareDialogForm.coursewareType === 3) {
        if (this.$zwxBase.verifyIsNotBlank(this.coursewareDialogForm.examId)) {
          this.$set(this.msForm.tbCoursewareInfoExpList[this.coursewareDialogForm.coursewareIndex], 'examId', this.coursewareDialogForm.examId)
        }
      }
      this.coursewareDialogForm.selectRadio = ''
      this.$refs.coursewareDialogRef.show(false)
    },

    //教材/ 考试 弹框 分页
    coursewareDialogCurrent(currentPage) {
      if (this.coursewareDialogForm.coursewareType === 2) {
        this.getTeachingMaterialList(currentPage, this.coursewareDialogForm.coursewareIndex)
      } else {
        this.getExamConfigList(currentPage, this.coursewareDialogForm.coursewareIndex)
      }
    },
    /**
     * 课件类别选择弹框部分
     */
    //表格选择事件
    handleCoursewareSelectionChange(selection) {
      if (this.coursewareDialogForm.coursewareType === 2) {
        this.coursewareDialogForm.coursewareTitle = selection.fileName
        this.coursewareDialogForm.teachingMaterialUid = selection.uuid
        this.coursewareDialogForm.classHour = selection.classHour
      } else {
        this.coursewareDialogForm.coursewareTitle = selection.examName
        this.coursewareDialogForm.examId = selection.rid
      }
    },

    /**
     * 相关资料添加 打开弹框
     */
    addRelatedData() {
      this.getRelatedDataList(1)
      this.$refs.relatedDataDialogRef.show(true)
    },
    // 相关资料删除
    materialDeletList(row) {
      this.msForm.tdCourseRelatedDataExpList.splice(row, 1)
    },
    // 相关资料 弹框 取消
    relatedDataDialogCancel() {
      this.$refs.relatedDataDialogRef.show(false)
    },
    // 相关资料 弹框 关闭
    relatedDataDialogClose() {
      this.relatedDataDialogForm.relatedDataNameLike = ''
      this.relatedDataDialogForm.relatedDataCurrentPage = ''
      this.relatedDataDialogForm.dataId = ''
      this.multipleSelection = []
    },
    // 相关资料列表 分页
    relatedDataCurrent(currentPage) {
      this.getRelatedDataList(currentPage)
    },
    // 相关资料 多选事件
    relatedDataSelectionChange(val) {
      this.multipleSelection = val
    },
    // 相关资料弹框 确定事件
    relatedDataSubmit() {
      if (this.$zwxBase.verifyIsNotBlank(this.multipleSelection)) {
        this.multipleSelection.forEach(item => {
          this.msForm.tdCourseRelatedDataExpList.push({ dataUid: item.uuid, categoryCode: 2, fileName: item.fileName, fileType: item.fileType, filePath: item.filePath })
        })
      }
      this.$refs.relatedDataDialogRef.show(false)
    },
    /**
     * 点击保存
     */
    addOrModifyTrainingCourse() {
      this.$refs.msForm.validate(valid => {
        if (valid) {
          if (this.$zwxBase.verifyIsBlank(this.msForm.tbCoursewareInfoExpList)) {
            this.$system.notify('错误', '课件信息不得为空', 'error')
          } else {
            // 保存
            this.saveData()
          }
        }
      })
    },
    // 获取上传题目图片文件的UID
    generateFilesUid() {
      this.$emit('loading', true)
      let data = {
        folder: 'saas/' + this.config.domainPrefix + '/course',
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-1', data, true, true, data => {
        if (data.type === '00') {
          this.$refs.coverPictureRef.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    // 上传文件之后 保存数据
    saveData() {
      let data = {
        // rid: this.rid,
        uuid: this.msForm.uuid,
        courseName: this.msForm.courseName,
        courseDesc: this.msForm.courseDesc,
        requiredClassHour: this.msForm.requiredClassHour,
        coverPicture: this.$zwxBase.verifyIsNotBlank(this.msForm.annexList) ? this.msForm.annexList[0].filePath : null,
        existExam: this.msForm.existExam,
        ifEnable: this.msForm.ifEnable,
        tbCoursewareInfoExpList: this.msForm.tbCoursewareInfoExpList.map(v => {
          if (v.coursewareType === 2) {
            v.requiredCourse = !!v.requiredCourse
          }
          return v
        }),
        tdCourseRelatedDataExpList: this.msForm.tdCourseRelatedDataExpList,
        courseType: this.showFiled ? this.msForm.courseType : '',
        courseTraineeType: this.msForm.courseTraineeType,
        courseTrainingType: this.msForm.courseTrainingType,
      }
      this.$system.postJson(
        this.api + '/training/platform/addOrModifyTrainingCourse-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$router.push({
              name: 'CourseManage',
              params: { fresh: true },
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            this.$emit('loading', false)
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
            this.$emit('loading', false)
          }
        },
        this.error
      )
    },
    /**
     * 教材列表查询
     */
    getTeachingMaterialList(currentPage, coursewareIndex) {
      this.coursewareDialogForm.coursewareCurrentPage = currentPage
      var filterRidList = []
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.tbCoursewareInfoExpList)) {
        for (let index = 0; index < this.msForm.tbCoursewareInfoExpList.length; index++) {
          const element = this.msForm.tbCoursewareInfoExpList[index]
       
          if (this.$zwxBase.verifyIsNotBlank(element.coursewareType) && element.coursewareType === 2 && this.$zwxBase.verifyIsNotBlank(element.teachingMaterialUid) && this.$zwxBase.verifyIsNotBlank(coursewareIndex) && coursewareIndex !== index) {
            filterRidList.push(element.teachingMaterialUid)
          }
        }
      }
      let data = {
        currentPage: this.coursewareDialogForm.coursewareCurrentPage,
        fileName: this.coursewareDialogForm.materialNameLike,
        categoryCodeArr: ['1'],
        ifEnable: true,
        filterUuidArr: filterRidList,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getTeachingMaterialList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.coursewareDialogForm.teachingMaterialList = data.teachingMaterialList
            this.coursewareDialogForm.materialTotal = data.teachingMaterialCount
            this.coursewareDialogForm.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 考试列表查询
     */
    getExamConfigList(currentPage, coursewareIndex) {
      this.coursewareDialogForm.coursewareCurrentPage = currentPage
      var examRidList = []
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.tbCoursewareInfoExpList)) {
        for (let index = 0; index < this.msForm.tbCoursewareInfoExpList.length; index++) {
          const element = this.msForm.tbCoursewareInfoExpList[index]
          if (this.$zwxBase.verifyIsNotBlank(element.coursewareType) && element.coursewareType === 3 && this.$zwxBase.verifyIsNotBlank(element.examId) && this.$zwxBase.verifyIsNotBlank(coursewareIndex) && coursewareIndex !== index) {
            examRidList.push(element.examId)
          }
        }
      }
      let data = {
        currentPage: this.coursewareDialogForm.coursewareCurrentPage,
        examName: this.coursewareDialogForm.examNameLike,
        ifEnable: true,
        examRidList: this.$zwxBase.verifyIsNotBlank(examRidList) ? examRidList.join(',') : null,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getExamConfigList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.coursewareDialogForm.examConfigList = data.examConfigList
            this.coursewareDialogForm.examTotal = data.examConfigCount
            this.coursewareDialogForm.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 相关资料列表查询
     */
    getRelatedDataList(currentPage) {
      this.relatedDataDialogForm.relatedDataCurrentPage = currentPage
      var filterRidList = []
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.tdCourseRelatedDataExpList)) {
        this.msForm.tdCourseRelatedDataExpList.forEach(item => {
          filterRidList.push(item.dataUid)
        })
      }
      let data = {
        currentPage: this.relatedDataDialogForm.relatedDataCurrentPage,
        fileName: this.relatedDataDialogForm.relatedDataNameLike,
        categoryCodeArr: ['2'],
        ifEnable: true,
        filterUuidArr: filterRidList,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getTeachingMaterialList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.relatedDataDialogForm.relatedDataList = data.teachingMaterialList
            this.relatedDataDialogForm.relatedDataTotal = data.teachingMaterialCount
            this.relatedDataDialogForm.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 课件 层级编码校验
     */
    levelCodeNoValidate(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        var list = this.msForm.tbCoursewareInfoExpList
        var count = 0
        if (this.$zwxBase.verifyIsNotBlank(list)) {
          list.forEach(item => {
            if (value == item.levelCodeNo) {
              count++
            }
          })
          if (count > 1) {
            return callback(new Error('层级编码不能重复'))
          }
        }
        return callback()
      } else {
        return callback(new Error('请输入层级编码'))
      }
    },
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .topic-select > .el-input.el-input--suffix > .el-input__inner {
  width: 100px !important;
}
/deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
  box-shadow: none;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 40px !important;
}
.courseware-dialog .el-dialog__footer {
  border-top: unset !important;
}

/deep/.courseware-dialog .el-radio.zwx-radio-course > .el-radio__input + .el-radio__label {
  display: none;
}
.relatedData-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      width: 600px !important;
      min-width: 600px !important;
    }
  }
}

.course-manage-edit {
  /deep/.el-tabs__item:hover {
    color: #303133 !important;
  }
  /deep/.el-tabs__item.is-active {
    color: #194dff !important;
  }
  /deep/.el-tabs__active-bar {
    background-color: #194dff !important;
  }
}
</style>
